import { init } from '@sentry/react';
import { VITE_SENTRY_DSN, isProdEnv, isStagingEnv } from 'src/env';

export function initSentry() {
  const dsn = VITE_SENTRY_DSN;
  if (dsn) {
    init({
      dsn: String(dsn),
      environment: getEnv(),
      ignoreErrors: [new RegExp(/ResizeObserver loop/, 'i')],
    });
    console.log('Sentry init env', getEnv());
  } else {
    console.warn('Sentry did not initialize because dsn value is missing');
  }
}

const getEnv = () => {
  if (isProdEnv()) return 'production';
  else if (isStagingEnv()) return 'staging';
  else return 'development';
};
